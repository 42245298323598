<template>
	<label class="checkbox" :class="className" :style="style">
		<input
			:type="type"
			:value="val"
			v-model="model"
			class="checkbox__input"
			@change="$emit('change')"
			:disabled="disabled"
			:class="{ greyAccent: disabled }"
		/>
		<div class="checkbox__wrap">
			<p class="checkbox__title">
				<slot />
			</p>
		</div>
	</label>
</template>
<script>
import "../../assets/styles/components/checkbox.scss";

export default {
	name: "AppCheckbox",
	props: {
		type: {
			type: String,
			default: "checkbox",
		},
		value: {
			type: [String, Boolean, Array, Number],
		},
		val: {
			type: [String, Number, Boolean, Object],
		},
		size: {
			type: [String, Number],
			default: 20,
		},
		fontSize: {
			type: [String, Number],
			default: 14,
		},
		fontWeight: {
			type: [String, Number],
			default: 500,
		},
		lineHeight: {
			type: [String, Number],
			default: 16,
		},
		radius: {
			type: [String, Number],
			default: 4,
		},
		spaceBetween: {
			type: [String, Number],
			default: 15,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		className() {
			return {
				"checkbox--radio": this.type === "radio",
			};
		},
		style() {
			const style = {
				"--size": this.size + "px",
				"--font-size": this.fontSize + "px",
				"--line-height": this.lineHeight + "px",
				"--font-weight": this.fontWeight,
				"--radius": this.radius + "px",
				"--space-between": this.spaceBetween + "px",
			};
			return style;
		},
		model: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
};
</script>
<style scoped lang="scss">
.greyAccent {
	accent-color: #ccc !important;
}
</style>

