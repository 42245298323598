<template>
	<div>
		<app-text class="form-label__title mb-10" size="12" line-height="16" v-if="title">{{ title }}</app-text>
		<div
			class="multiselect"
			id="countries"
			multiple="multiple"
			data-target="multi-0"
			v-click-outside="close"
			:class="{ opened: show, 'is-invalid': isInvalid }"
		>
			<div
				class="title noselect"
				:class="{ opened: show }"
				@blur="onBlur"
				@focus="onFocus"
				@click="open()"
				ref="select"
			>
				<p
					v-if="(multiple && selectedItems.length === 0) || (!multiple && selectedItems.length === 0)"
					class="text"
				>
					{{ defaultText }}
				</p>

				<template v-else-if="selectedItems.length > 0">
					<ul class="selected" v-if="multiple">
						<template v-if="selectedItems.length < counterNumber">
							<li v-for="(item, idx) in selectedItems" :key="idx">
								{{
									translatable && item[itemText]
										? item[itemText][`${$store.state.clng}`]
										: item[itemText]
								}}
								<span @click="removeItem(item, true)">&times;</span>
							</li>
						</template>
						<li v-else>{{ selectedItems.length }} {{ counterText }}</li>
					</ul>
					<span v-else>{{
						translatable && selectedItems[0][itemText]
							? selectedItems[0][itemText][`${$store.state.clng}`]
							: selectedItems[0][itemText]
					}}</span>
				</template>
				<span class="close-icon">&times;</span>
				<span class="expand-icon" @click="open()"></span>
			</div>
			<transition name="app-dropdown">
				<div class="list-container" v-if="show">
					<div
						class="app-dropdown__item"
						v-if="searchable"
						style="padding-top: 18px; padding-bottom: 18px; background: white"
					>
						<app-input
							v-model="inputValue"
							style="width: 100%"
							:placeholder="$t('search')"
							@input="search"
						/>
					</div>
					<template v-if="multiple">
						<ul>
							<li
								v-if="multiple && list.length > 0"
								class="multiselect-item select-all"
								@click="
									selectAll = !selectAll;
									selectAllItems();
								"
								:class="{ 'multiple-select': multiple, 'select-all-selected': selectAll }"
							>
								{{ selectAllOptions }}
							</li>
							<li
								class="multiselect-item"
								v-for="(item, idx) in list"
								:key="idx"
								:class="{ 'multiple-select': multiple, active: hasInSelected(item) }"
								@click="select(item)"
							>
								<span class="mr-10" v-show="selectType === 'checkbox'">
									<input
										type="checkbox"
										:value="item"
										v-model="selectedItems"
										@click="select(item)"
									/>
								</span>
								<template v-if="translatable">
									{{ item[itemText] | translate }}
								</template>
								<template v-else>
									{{ item[itemText] }}
								</template>
							</li>
						</ul>
					</template>
					<template v-else-if="list.length > 0">
						<ul>
							<li
								class="single-item"
								v-for="(item, idx) in list"
								:key="idx"
								@click="selectOnSingle(item)"
							>
								<template v-if="translatable">
									{{ item[itemText] | translate }}
								</template>
								<template v-else>
									{{ item[itemText] }}
								</template>
							</li>
						</ul>
					</template>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import vClickOutside from "v-click-outside";
import AppText from "@/components/shared-components/AppText";
import AppCheckbox from "@/components/shared-components/AppCheckbox";
import CustomScrollbar from "./CustomScrollbar";
import AppInput from "./AppInput";

export default {
	name: "app-multiple-select",
	props: {
		searchable: {
			type: Boolean,
			default: false,
		},
		itemValue: {
			type: String,
			default: "id",
		},
		itemText: {
			type: String,
			default: "name",
		},
		translatable: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		defaultText: {
			type: String,
			default: "Выбирать",
		},
		val: {
			type: [Array, Object],
			default: () => [],
		},
		list: {
			type: Array,
			required: true,
			default: () => [],
		},
		counterText: {
			type: String,
			default: "ta element tanlandi",
		},
		counterNumber: {
			type: [Number, String],
			default: 3,
		},
		isInvalid: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "",
		},
		selectType: {
			type: String,
			default: "select",
			validator: function (value) {
				const availableTypes = ["select", "checkbox"];
				return availableTypes.includes(value);
			},
		},
		singleItem: {
			type: Boolean,
			default: false,
		},
		selectAllText: {
			type: String,
		},
		selectors: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selectedItems: [],
			show: false,
			selectAll: false,
			inputValue: null,
			timeout: null,
		};
	},
	components: {
		AppText,
		AppInput,
		AppCheckbox,
		CustomScrollbar,
	},
	directives: { clickOutside: vClickOutside.directive },
	created() {
		this.initValues();
	},
	methods: {
		search() {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.$emit("onSearch", this.inputValue);
				this.selectedItems = [];
			}, 500);
		},
		select(item) {
			if (!this.hasInSelected(item)) {
				this.selectedItems.push(item);
			} else {
				this.removeItem(item);
			}

			if (this.selectedItems.length === this.list.length) {
				this.selectAll = true;
			} else if (this.selectedItems.length === 0) {
				this.selectAll = false;
			}

			this.emitItem(item);
		},
		selectOnSingle(item) {
			this.selectedItems = [item];
			this.emitSingleItem();
			this.close();
		},
		selectAllItems() {
			if (this.selectAll) {
				this.selectedItems = this.list;
			} else {
				this.selectedItems = [];
				this.selectAll = false;
			}

			this.$emit("change", this.selectedItems);
		},
		initValues() {
			if (this.multiple && this.val.length > 0) {
				this.selectedItems = this.val;
				this.selectAll = this.val.length === this.list.length;
			}
		},
		open() {
			this.show = !this.show;
		},
		close() {
			this.show = false;
		},
		onBlur() {
			this.close();
		},
		onFocus() {
			this.close();
		},
		removeItem(item, isTag = false) {
			this.show = true;
			this.selectedItems = this.selectedItems.filter((e) => e[this.itemValue] !== item[this.itemValue]);

			if (isTag) {
				this.emitItem(item);
			}
		},
		emitSingleItem() {
			const result = this.singleItem ? this.selectedItems[0][this.itemValue] : this.selectedItems[0];
			this.$emit("change", result);
		},
		emitItem(item) {
			const result = this.singleItem ? item : this.selectedItems;
			this.$emit("change", result);
		},
		hasInSelected(item) {
			return this.selectedItems.findIndex((el) => el[this.itemValue] === item[this.itemValue]) > -1;
		},
		clearAllSelected() {
			this.selectedItems = [];
			this.selectAll = false;
			this.$emit("change", this.selectedItems);
		},
	},
	computed: {
		selectAllOptions() {
			return this.selectAllText ? this.selectAllText : this.$t("select_all");
		},
	},
	watch: {
		// selectedItems(va) {
		// 	console.log(va);
		// },
		selectors(val) {
			if (val.length === 0) {
				this.selectedItems = [];
			} else {
				let allSelected = [];
				val.forEach((data) => {
					let filtered = this.selectedItems.filter((dis) => dis.regionId === data.regionId);
					allSelected = [...allSelected, ...filtered];
				});
				this.selectedItems = allSelected;
			}
		},
		val: function (val) {
			this.initValues();
		},
		list() {
			this.selectedItems = [];
		},
	},
};
</script>
<style scoped>
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 40px;
}

.multiselect {
	width: 100%;
	font-size: 15px;
	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	transition: 0.2s;
	outline: none;
	position: relative;
	transition: transform 0.5s ease-out;
}
/*
.multiselect:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
}*/

.multiselect.active {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-bottom: 1px solid transparent;
}

.multiselect > .title {
	cursor: pointer;
	padding: 6px 8px;
}

.multiselect > .title > .expand-icon::after {
	transition: transform 0.15s ease-out;
	display: block;
	content: "";
	width: 16px;
	height: 16px;
	background: url(/img/chevron-down-gray.24ebba88.svg) center/contain no-repeat;
	margin-left: auto;
	flex-shrink: 0;
	position: absolute;
	right: 10px;
	top: 13px;
	z-index: -11111;
}

.multiselect > .title.opened > .expand-icon::after {
	transform: rotate(180deg);
}

.multiselect > .title > .close-icon {
	float: right;
	border-radius: 50%;
	padding: 0 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	font-weight: 700;
	transition: 0.2s;
	display: none;
}

.multiselect.selection > .title > .close-icon {
	display: block;
}

.multiselect > .title > .close-icon:hover {
	border: 1px solid rgba(0, 0, 0, 0.3);
	background: rgb(203, 32, 32);
	color: #fff;
}
.multiselect > .list-container::-webkit-scrollbar {
	width: 5px;
	background-color: #dfeaf3 !important;
}

.multiselect > .list-container::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
	border-radius: 10px;
	background-color: #f5f5f5 !important;
}

.multiselect > .list-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
	background-color: #d62929 !important;
}

.multiselect > .list-container {
	overflow: auto;
	margin-top: 5px;
	margin-left: -1px;
	width: 100%;
	transition: 0.2s;
	position: absolute;
	z-index: 99;
	box-shadow: 0px 4px 16px rgb(0 0 0 / 6%);
	border-top: none;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.multiselect.active > .list-container {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	border-top: 0;
}

.multiselect:hover > .list-container {
	border-top-color: rgba(0, 0, 0, 0.3);
}

.multiselect.active:hover > .list-container {
	border-color: rgba(0, 0, 0, 0.3);
}

.multiselect > .list-container > option {
	padding: 5px;
	cursor: pointer;
	transition: 0.2s;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
}

.multiselect > .list-container > option.selected {
	background: rgb(122, 175, 233);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	color: #fff;
}

.multiselect > .list-container > option:hover {
	background: rgba(0, 0, 0, 0.1);
	color: #000;
}

.multiselect.active > .list-container > option {
	display: block;
}

.multiple-select {
	background-color: #ccc;
}

.multiselect-item {
	cursor: pointer;
}

ul.selected {
	width: 100%;
}

ul.selected li {
	display: inline-block;
	padding: 4px 7px;
	background-color: #1f2d42;
	color: white;
	margin-right: 5px;
	border-radius: 8px;
	font-weight: 500;
	margin-top: 5px;
	font-size: 11px;
}
ul.selected li span {
	font-weight: 600;
	padding: 2px;
}
div.list-container,
div.list-container ul {
	padding: 0;
	max-height: 180px;
}
div.list-container ul {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
div.list-container ul li {
	/* padding: 5px 7px; */
	background-color: #fff;
	font-weight: 500;
	font-size: 11px;
	line-height: 18px;
	padding: 10px 20px;
	min-height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
p.text {
	padding: 5px 8px;
}

.multiselect-item.multiple-select.active {
	background-color: #ccc;
}

.multiselect.opened {
	border: 1px solid #1f2d42;
}
.select-all {
	font-size: 13px !important;
	text-transform: uppercase;
	padding-left: 20px !important;
	border-bottom: 1px solid #ccc;
}
.select-all-selected {
	background-color: darkgrey !important;
}
</style>
